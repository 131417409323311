@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap');

.App {
  background-image: url(../../assets/background_img.png);
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
}

.Tagline{
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

.head{
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 150.68px;
  line-height: 1.2;
  color: #FFFFFF;
  margin: 0;
}

.tag {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 58.84px;
  line-height: 0.5;  
  letter-spacing: 0.216px;
  color: #FFFFFF;
  margin: 0;
}

.tombolPlayStore {
  margin-top: 6rem;
  width: 27%;
}